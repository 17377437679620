// Custom styling
.centered_submit {
    display: flex; 
    flex-direction: column; 
    margin: 0 auto;
    width: 303px;
}

#logo {
    max-width: 15em;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    justify-self: flex-start;
    align-self: flex-start;
    height: auto;
}

.navbar-custom {
    background-color: $gray-lighter;
    border-color: transparent;
    .navbar-brand {
        color: $theme-primary;
        @include script-font;
        &:hover,
        &:focus,
        &:active,
        &.active {
            color: darken($theme-primary, 10%);
        }
    }
    .navbar-collapse {
        border-color: fade-out($theme-primary, .02);
    }
    .navbar-toggle {
        position:absolute;
        top: 20%;
        right: 0;
        background-color: $theme-primary;
        border-color: $theme-primary;
        @include heading-font;
        color: white;
        font-size: 12px;
        &:hover,
        &:focus {
            background-color: $theme-primary;
        }
    }
    .nav {
        li {
            a {
                @include heading-font;
                font-weight: 400;
                letter-spacing: 1px;
                color: $theme-primary;
                &:hover,
                &:focus {
                    color: lighten($theme-primary, 20%);
                    outline: $theme-primary;
                }
            }
        }
    }
    .navbar-nav>.active>a {
        border-radius: 0;
        color: white;
        background-color: $theme-primary;
    }
    .navbar-nav>.active>a:hover,
    .navbar-nav>.active>a:focus {
        color: white;
        background-color: darken($theme-primary, 10%);
    }
}

@media(min-width:768px) {
    #logo {
        max-width: 20em;
        height: auto;
    }
    
    .navbar-custom {
        background-color: transparent;
        -webkit-transition: padding 0.3s;
        -moz-transition: padding 0.3s;
        transition: padding 0.3s;
        border: none;
        .navbar-brand {
            font-size: 2em;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            transition: all 0.3s;
        }
        .navbar-nav>.active>a {
            border-radius: 3px;
        }
    }
}

// Navbar Change on Scroll

@media(min-width:768px) {
    .navbar-custom.affix {
        background-color: $gray-darker;
        padding: 10px 0;
        .navbar-brand {
            font-size: 1.5em;
        }
    }
}
.underlined {
    text-decoration: underline;
    text-decoration-color: $theme-primary;
}

.services {
    flex-wrap: wrap; 
    display: flex; 
    flex-direction: row; 
    justify-content: space-between;
    max-width: fit-content;
}

.service {
    flex: 1;
}

@media(min-width:768px) {
    #logo {
        max-width: 30em;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 30px;
        justify-self: flex-start;
        align-self: flex-start;
        height: auto;
    }

    .text-muted {
        color: $gray-light;
        font-size: 15px;
    }

    .nav {
        position: absolute;
        top: 50%;
        right: 10px;
        li {
            a {
                @include heading-font;
                font-weight: 400;
                letter-spacing: 1px;
                //padding: 15px 32px;
                text-align: center;
                text-decoration: none;
                background-color: transparent;
                font-size: 20px;
                //min-width: 100px;
                //margin: 4px 2px;
                height: auto;
                cursor: pointer;
               // padding: 5px 10px;
                margin-right: 10px;
                background: #fff;
            }
        }
        li+li {
                border-left: 1px solid $theme-primary;
        }
    }

    .centered_submit {
        display: flex; 
        flex-direction: row; 
        justify-content: center;
    }
    
    .service {
        flex: 1;
        flex-basis: auto;
    }
    
}